$backgroundColor: #ffffff
$bodyColor: #000000
$bodyFont: -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"

body
  scrollbar-color: blue orange

.tab-style-07 .nav-tabs > li.nav-item > a.nav-link.active
  border-color: #de5cff

.bg-dark-hero
  background-color: #0d0d0d

.bg-dark-1
  background-color: #f0f

.bg-dark-2
  background-color: #050505

.bg-dark-3
  background-color: #212121

.btn-transparent-dark-2
  background: transparent
  border-color: #eee

.btn-transparent-dark-2:hover, .btn.btn-transparent-dark-2:focus
  background: #212121
  border-color: #212121

.btn-transparent-dark-3
  background: transparent
  border-color: #eee

.btn-transparent-dark-3:hover, .btn.btn-transparent-dark-3:focus
  background: #050505
  border-color: #050505


.feature-box.feature-box-hover:hover
  background-color: #212121

.content-button
  cursor: pointer

.split-hero-left, .split-hero-right, #split-hero
  background: rgb(13,13,13)
  background: linear-gradient(180deg, #0d0d0d 0%, #131313 80%, #222 100%)
  background: linear-gradient(180deg, #0d0d0d 0%, #131313 80%, #201623 100%)
  background: linear-gradient(180deg, #0d0d0d 0%, #131313 80%, #39273f 100%)

.btn-feature
  background: #de5cff
  color: #eee

.btn-feature:hover
  background: #c000f0
  color: #fff

.fluid-hero
  .hero-text
    max-width: 1400px
    width: 100%
    transform: translate3d(-50%, 0, 0)
    left: 50%
    top: 50%

.feature-section
  padding-top: 5rem
  padding-bottom: 5rem

.no-pointer-events
  pointer-events: none

.logo
  font-family: 'Anonymous Pro', monospace

nav .logo
  font-size: 4rem

.hero-subtitle
  font-family: 'Roboto Mono', monospace
  letter-spacing: -3px

.feature-section a, .feature-section p
  font-family: 'Roboto Mono', monospace
  font-size: 1.5rem
  
.services-row
  font-size: 1.8rem

.tech-font
  font-family: 'Roboto Mono', monospace

.text-purple
  color: #c000f0
  color: #de5cff

.text-dark-purple
  color: #c000f0

.navbar.bg-transparent
  @media (max-width: 992px)
    background: #000 !important

#services a.nav-link
  @media (max-width: 767px)
    padding: 1.6rem 0
    margin: 1.2rem 0

html
  // scroll-behavior: smooth
  background: black

  @media (max-width: 767px)
    font-size: 10px

.quote-btn
  border: 2px solid #c000f0
  border-radius: 3px
